import React, { useEffect, useState } from 'react'
import svg from '../svg'
import { setMyState, common, dataLoader } from '../Comman'
import { NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { appStore } from '../../zustand/store';
import config from '../../config';
import {agendaData} from './agendaData'

function Agenda(props) {
    let alfaUrl = config.REACT_APP_BUCKET_URL
    const eventId = props.userData?.event_id;
  
    let myStore = appStore(state => state);
    let userData = myStore.userData;

    const [state, setQuery] = useState({
        bodyColor: '#f7f7f7',
        themeColor: '#0B9D7C',
        textColor: '#4A5251',
        hoverColor: null,
        userAgentaList: [],
        dataLoading:false,
        daysTab : 'Day-1',
    })
    let getHover1 = (e) => {
        setMyState(setQuery, {
            hoverColor: e,
        })
    }
    let getHover2 = (e) => {
        setMyState(setQuery, {
            hoverColor: null,
        })
    }
    const getAgentalist = () => {
        setMyState(setQuery, {
            dataLoading: true
        })
        common.getAPI({
            isLoader: true,
            url: 'get-agentalist',
            method: 'POST',
            data: { event_id: userData.event_id },
        }, (resp) => {
            setMyState(setQuery, {
                dataLoading: false
            })
            if (resp.success == true) {
                setMyState(setQuery, {
                    userAgentaList: resp.agenta_list
                })
            }
        })
    }
    useEffect(() => {
        
        if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'ucs'){
            setMyState(setQuery, {
                userAgentaList: agendaData
            })
        }else{
            getAgentalist()
        }
    }, [])
    
    useEffect(() => {
        let c1 = document.getElementById("mainfooter");
        let header = document.getElementById("mainHeaderMenu");
       
          if (c1.classList.contains("hidden")) {
            c1.classList.remove("hidden");
            header.classList.remove("hidden");
          }
        
      }, []);
    return (
        <>
            <div className='mc_eventBody'
                style={{ backgroundColor: state.bodyColor, color: state.textColor }}
            >
                <div className='mc_componentData'>
                    <div className='mc_daysList'>
                        <ul>
                            <li className={`mc_listAgenda ${state.daysTab == 'Day-1' ? 'isActive' : ''}`} onClick={()=> setMyState(setQuery, {
                                daysTab : 'Day-1'
                            })}>Day 1</li>
                            <li className={`mc_listAgenda ${state.daysTab == 'Day-2' ? 'isActive' : ''}`} onClick={()=> setMyState(setQuery, {
                                daysTab : 'Day-2'
                            })}>Day 2</li>
                         { userData?.ticket_code.substring(0, 3).toLowerCase() !== 'ucs' &&  <li className={`mc_listAgenda ${state.daysTab == 'Day-3' ? 'isActive' : ''}`} onClick={()=> setMyState(setQuery, {
                                daysTab : 'Day-3'
                            })}>Day 3</li>}
                        </ul>
                    </div>
                    <div className='mc_headTitle_dv' >
                        <h2 style={{ color: '#000' }}>{props.title} <span className='mc_titleCount' style={{color : state.themeColor}}></span></h2>
                    </div>
                    <div className='mc_eventListHolder mc_agendaList'>
                        {/* List Use 1 */}
                        {state.dataLoading ? [...Array(3)].map(count => <div key={count}>{dataLoader.eventLoader}</div>) :
                            state.userAgentaList.map((agenda, i) => {
                                let [day, date, timeRange] = agenda.date.split(",");
                                if(agenda.day == state.daysTab){
                                return (
                                    <div className={`mc_eventList flex flex-wrap mb-4`} key={i}>
                                        <div className='mc_agendaCOntemt w-full'>
                                            <h6 className='text-sm font-semibold mb-1'>{agenda.topic}</h6>
                                            {/* <p className='text-xs font-light'>High Tea, Networking, Desdicated Task.</p> */}
                                            {agenda.speaker_name && 
                                            <div className='mc_adendaProfile mb-3 mt-3 w-full flex flex-wrap'>
                                                <img src={agenda.img ? agenda.img : `${alfaUrl}/images/user.png`} width={'20px'} height={'20px'} className='rounded-full mr-2' />
                                                <span className='text-xs' style={{color: state.themeColor}}>{agenda.speaker_name}</span>
                                            </div>
                                            }
                                            {
                                                agenda.speakerArr && agenda.speakerArr.map((val)=> <div className='mc_adendaProfile mb-1 mt-3 w-full flex flex-wrap'>
                                                <img src={val.img ? val.img : `${alfaUrl}/images/user.png`} width={'20px'} height={'20px'} className='rounded-full mr-2' />
                                                <span className='text-xs' style={{color: state.themeColor}}>{val.speaker_name}</span>
                                            </div>)
                                            }
                                        </div>
                                        <div className='mc_dateTime flax items-center justify-center flex-wrapper'>
                                            <span className='mc_calangerList text-sm'>
                                                {svg.app.calanderIconList} <span>{date}</span>
                                            </span>
                                            <span className='mc_timeList text-sm'>
                                                {svg.app.clockIconList} <span>{timeRange}</span>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            }
                            )
                        }



                    </div>
                </div>
            </div>
        </>
    )
}

export default Agenda;
