import { useState, useEffect, useRef } from 'react';
// import { setAttributes } from 'video.js/dist/types/utils/dom';


window.videoDuration = 0;
const VideoPlayer = (props) => {
//  console.log(props, 'InnerVideoProps');
  const [currentTimeCount, setCurrentTimeCount] = useState(0);
  const playerRef = useRef(null);

  const [embedCode , setembedCode]=useState("")


  useEffect(()=>{
    if(embedCode!=props.embedCode){
        setembedCode(props.embedCode)
      }
  },[props.embedCode])

  // let modifiedEmbedCode = props.embedCode;
  // // Add autoplay attribute if it's an iframe
  // if (modifiedEmbedCode.includes('<iframe')) {
  //   modifiedEmbedCode = modifiedEmbedCode.replace('<iframe', '<iframe autoplay="true"');
  // }
  // const [embedCodeUrl, setEmbedCodeUrl] = useState('')
  // setEmbedCodeUrl(modifiedEmbedCode);
  // console.log(embedCodeUrl, 'embedCodeUrl');

  const [embedCodeUrl, setEmbedCodeUrl] = useState('')
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5 // Trigger when 50% of the player is visible
    };
    if (props.embedCode) {

      let s1 = document.createElement("script");
      s1.type = "text/javascript";
      s1.src = 'https://player.vdocipher.com/v2/api.js';
      
      document.head.appendChild(s1);
      s1.onload = function() {
      let embedHtml = document.getElementById('embedDv');
      var iframeElement = embedHtml.querySelector('iframe');
      let player = new window.VdoPlayer.getInstance(iframeElement);
      // let iframeUrl = iframeElement.src;
      // iframeUrl = iframeUrl+`&autoplay=true`;
      // iframeElement.setAttribute('src', iframeUrl+`&autoplay=true`)
      // console.log(iframeUrl, 'player');
      
      // console.log(props.showFloatVideo, 'props.showFloatVideo');
      // if(props.showFloatVideo == true){
      //   console.log('iframeUrl');
      //   setEmbedCodeUrl(iframeUrl)
      // }else{
      //   let iframeUrl = iframeElement.src;
      //   iframeUrl = iframeUrl+`&autoplay=true`;
      //   const srcUrl = iframeElement.getAttribute('src')
      //   const updatedSrc = srcUrl.replace('&autoplay=true', ''); // Remove autoplay parameter
      //   let setUrlIframe = iframeElement.setAttribute('src', updatedSrc);
      //   console.log(setUrlIframe, 'iframeUrlElse');
      // }
      
      // const src = iframeElement.getAttribute('src');
      // if(props.showFloatVide == true){
      //   iframeElement.setAttribute('src', `${src}&autoplay=true`);
      //   // setUrlIframe
      // }else if(src.includes('&autoplay=true')) {
      //   const updatedSrc = src.replace('&autoplay=true', ''); // Remove autoplay parameter
      //   iframeElement.setAttribute('src', updatedSrc);
      // }
      
      // if (iframeUrl.includes('&autoplay=true')) {
      //   console.log('isPlay');
      //   const updatedSrc = iframeUrl.replace('&autoplay=true', ''); // Remove autoplay parameter
      //   iframeElement.setAttribute('src', updatedSrc);
      // }else{
      //   iframeElement.setAttribute('src', `${iframeUrl}&autoplay=true`);
      // }
      player.video.addEventListener('play', function () {
        const { currentTime } = player.video; 
        window.videoDuration = currentTime;
        setCurrentTimeCount(window.videoDuration)
      });

      player.video.addEventListener("timeupdate", () => {
        const { currentTime } = player.video; 
        window.videoDuration = currentTime;
        setCurrentTimeCount(window.videoDuration)
      });
    }
    
    // const handleIntersect = (entries) => {
    //   entries.forEach(entry => {
    //     let embedHtml = document.getElementById('embedDv');
    //     var iframeElement = embedHtml.querySelector('iframe');
    //     const src = iframeElement.getAttribute('src');
    //       iframeElement.setAttribute('src', `${src}&autoplay=true`);
    //     if (entry.isIntersecting) {
    //       // Player is in view, remove autoplay
    //       // const iframe = playerRef.current.querySelector('iframe');
    //       // const src = iframeElement.getAttribute('src');
    //       // if (src.includes('&autoplay=true')) {
    //       //   const updatedSrc = src.replace('&autoplay=true', ''); // Remove autoplay parameter
    //       //   iframeElement.setAttribute('src', updatedSrc);
    //       // }
          
    //     } else {
    //       // Player is out of view, reapply autoplay
          
          
    //     }
    //   });
    // }
    // const observer = new IntersectionObserver(handleIntersect, options);
    // observer.observe(playerRef.current);

    // // Cleanup observer on unmount
    // return () => {
    //   observer.disconnect();
    // };
  }
    
  }, [currentTimeCount]);
  window.videoDuration = currentTimeCount

  // console.log(window.videoDuration, 'currentTimeCount');
  // const playerRef = useRef(null);

  
  return (

    <div id='vdo-player' className={props.embedCode ? "" : 'error_mes_video_not_ava'}>
      {
        props.embedCode ? <> 
        {/* <iframe ref={playerRef} title="Embedded Video" srcDoc={embedCode} frameBorder="0" allowFullScreen /> */}
        <div ref={playerRef} id='embedDv' dangerouslySetInnerHTML={{ __html: props.embedCode }} /> 
        </>
        : <div>
          <h1 className='text-white text-5xl mb-1'>Sorry</h1>
          <h2 className='text-white text-2xl'>This video is currently unavailable.</h2>
        </div>
      }
    </div>
  );
};

export default VideoPlayer;
