export const agendaData = [
    {
      "topic": "Registration Starts",
      "day": "Day-1",
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 8:00 am - 9.30 am"
    },
    {
      "topic": "Door Open",
      "day": "Day-1",
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 9.30 am"
    },
    {
      "topic": "Unicornism - How to Tune Your Mind to the Unicorn Frequency and Become a Unicorn Award Winner",
      "day": "Day-1",
      "speaker_name": "Mr. Gopal Krishnan",
      "img": "https://img.flexifunnels.com/images/5/gopal21500_k5otu_500.webp",
      "date": "Saturday, 4 May 2024, 10:00 am - 11:00 am"
    },
    {
      "topic": "Tea Break",
      "day": "Day-1",
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 11:00 am - 11:15 am"
    },
    {
      "topic": "Panel Discussion on Unicorn Mindset with Rahul Bhatnagar and Saurabh Bhatnagar",
      "day": "Day-1",
       'speakerArr' :[{"speaker_name":"Mr. Rahul Bhatnagar","img":"https://img.flexifunnels.com/images/943/k5ode_634_uyoda634Layer21.webp"},{"speaker_name":"Mr. Saurabh Bhatnagar","img":"https://img.flexifunnels.com/images/943/u4mze_700_axnzu700Layer3copy3.webp"}],
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 11.30am - 12:15 pm"
    },
    {
      "topic": "How to Convert Marketing into a Habit",
      "day": "Day-1",
      "speaker_name": "Mr. Nirmal Chandran",
      "img": "https://img.flexifunnels.com/images/5/nirmal2500_mznzg_500.webp",
      "date": "Saturday, 4 May 2024, 12:15 pm - 1:15 pm"
    },
    {
      "topic": "Lunch Break",
      "day": "Day-1",
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 1:15 pm - 2:15 pm"
    },
    {
      "topic": "Hypnotism - How to Permanently Install the Unicorn Frequency Inside You",
      "day": "Day-1",
      "speaker_name": "Prasheek Gaikwad",
      "img": "/assets/images/eventsimg/prasheek_speaker.jpg",
      "date": "Saturday, 4 May 2024, 2.30 pm - 3.30 pm"
    },
    {
      "topic": "Panel Discussion with Sorav Jain, Divyanshu Damani & Dharaneetharan on How to become a Social Media Influencer",
      "day": "Day-1",
      'speakerArr' :[{"speaker_name":"Mr. Sorav Jain","img":"https://img.flexifunnels.com/images/5/speakers5500_gymte_500.webp"},{"speaker_name":"Mr. Divyanshu Damani","img":"https://img.flexifunnels.com/images/5/speaker500_a0mta_500.webp"},{"speaker_name":"Mr. Dharaneetharan","img":"https://img.flexifunnels.com/images/5/dharaneetharan500_q3nje_500.webp"}],
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 3.40 pm - 4.30 pm"
    },
    {
      "topic": "Tea Break",
      "day": "Day-1",
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 4.30 pm - 4.45 pm"
    },
    {
      "topic": "Unicorn Marketing - The Traffic Cop Scripting Formula",
      "day": "Day-1",
      "speaker_name": "Mr. Gopal Krishnan",
      "img": "https://img.flexifunnels.com/images/5/gopal21500_k5otu_500.webp",
      "date": "Saturday, 4 May 2024, 4.45 pm - 5.15 pm"
    },
    {
      "topic": "Panel Discussion with Indu Khatri & Mitesh Khatri on Law of Attraction",
      "day": "Day-1",
      "speaker_name": "Indu Khatri & Mitesh Khatri",
      "img": "https://img.flexifunnels.com/images/5/speakers7500_kxode_500.webp",
      "date": "Saturday, 4 May 2024, 5.15 pm - 6:00 pm"
    },
    {
      "topic": "Awards Night with Cultural - Keynote speech",
      "day": "Day-1",
      "speaker_name": "Dr. Velumani",
      "img": "https://img.flexifunnels.com/images/5/speakers500_e2mjm_500.webp",
      "date": "Saturday, 4 May 2024, 7:00pm - 7:30pm"
    },
    {
      "topic": "Distribution of Awards",
      "day": "Day-1",
      "speaker_name": "",
      "img": "",
      "date": "Saturday, 4 May 2024, 7:30pm - 9:30pm"
    },
    {
        "topic": "Luxury Car & Ramp Walk Celebration of Award Winners",
        "day": "Day-2",
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 9:30am - 10:00 am"
      },
      {
        "topic": "The Traffic Cop Retention Editing Formula for Video Ads/Instagram Reels",
        "day": "Day-2",
        "speaker_name": "Mr. Santhosh",
        "img": "https://img.flexifunnels.com/images/5/santhosh_u5nzg_500.webp",
        "date": "Sunday, 5 May 2024, 10:00 am - 11:00 am"
      },
      {
        "topic": "Tea Break",
        "day": "Day-2",
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 11:00 am - 11:15 am"
      },
      {
        "topic": "Panel Discussion with Ankit, Manjunath and Sandeep Gupta on scaling to 10 Crores in Revenue",
        "day": "Day-2",
        'speakerArr' :[{"speaker_name":"Mr. Ankit Neerav","img":"https:\/\/img.flexifunnels.com\/images\/5\/c0odk_600_Ankit.png"},{"speaker_name":"Dr. Manjunath","img":"https://img.flexifunnels.com/images/5/speakers9500_iwnju_500.webp"},{"speaker_name":"Dr. Sandeep Gupta","img":"https://img.flexifunnels.com/images/5/speaker1500_izmtc_500.webp"}],
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 11:15 am - 12:00 pm"
      },
      {
        "topic": "How to Install the Unicorn Scaling Mindset In Your Body",
        "day": "Day-2",
        "speaker_name": "Mr. Swastik Nandakumar",
        "img": "https://img.flexifunnels.com/images/5/speakers6500_kzotq_500.webp",
        "date": "Sunday, 5 May 2024, 12:00 pm - 12:30 pm"
      },
      {
        "topic": "How to Scale to 1 Cr months with EPIC Offers",
        "day": "Day-2",
        "speaker_name": "Mr. Gopal Krishnan",
        "img": "https://img.flexifunnels.com/images/5/gopal21500_k5otu_500.webp",
        "date": "Sunday, 5 May 2024, 12:30 pm - 1:30 pm"
      },
      {
        "topic": "Lunch",
        "day": "Day-2",
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 1:30 pm - 2:30 pm"
      },
      {
        "topic": "FlexiFunnels Demo",
        "day": "Day-2",
        "speaker_name": "Mr. Saurabh Bhatnagar",
        "img": "https://img.flexifunnels.com/images/943/u4mze_700_axnzu700Layer3copy3.webp",
        "date": "Sunday, 5 May 2024, 2:30 pm - 3:00 pm"
      },
      {
        "topic": "How to Support and Retain Your Customers Without Relying on Acquiring New Customers",
        "day": "Day-2",
        "speaker_name": "Mr. Karthik Ramani",
        "img": "https://img.flexifunnels.com/images/943/i5nzk_1080_Mr.KarthikRamaniPANELIST1.png",
        "date": "Sunday, 5 May 2024, 3:00 pm - 3:45 pm"
      },
      {
        "topic": "Panel - Rajitha & Savitha on Using Videos to Launch Coaching Business",
        "day": "Day-2",
        'speakerArr' :[{"speaker_name":"Dr. Rajitha Vanga","img":"https://img.flexifunnels.com/images/5/speakers10500_yyoty_500.webp"},{"speaker_name":"Dr. Savitha Suri","img":"https://img.flexifunnels.com/images/5/speakers11500_czotu_500.webp"}],
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 3:45 pm - 4:15 pm"
      },
      {
        "topic": "How to use magic on creatives to scale your ads",
        "day": "Day-2",
        "speaker_name": "Mr. Sagar Achhra",
        "img": "https://img.flexifunnels.com/images/943/c1mje_1079_SagarPhoto.png",
        "date": "Sunday, 5 May 2024,  4:15pm - 4:30pm"
      },
      {
        "topic": "Tea Break",
        "day": "Day-2",
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 4:30pm - 4:45 pm"
      },
      {
        "topic": "Unicorn Coaching - How to Deliver an Exceptional Experience to Your Clients Without Burning Yourself Out",
        "day": "Day-2",
        "speaker_name": "",
        "img": "",
        "date": "Sunday, 5 May 2024, 4:45 pm - 5:30 pm"
      },
      {
        "topic": "Interview with Gopal Krishnan",
        "day": "Day-2",
        "speaker_name": "Mr. Gopal Krishnan",
        "img": "https://img.flexifunnels.com/images/5/gopal21500_k5otu_500.webp",
        "date": "Sunday, 5 May 2024, 5:30 pm - 6:00 pm"
      }
  ];
  
 
  