
'use client';

import React, {useState , useCallback, useEffect} from 'react' 
import svg from '../../components/svg';
import { useNavigate, useLocation  } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {common, setMyState, authAction} from '../../components/Comman'; 
import { appStore } from '../../zustand/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
// import { Toast } from 'react-toastify/dist/components';
// import { PasswordChecklist } from '../../components/checkPassword';

export default function AuthPage({...props}) {
    const slugName = window.location.href.split('/')[4];
    const baseUrl = window.location.host+"/membership/"+slugName
    // console.log(baseUrl, 'window.location.href');
    let myStore = appStore(state => state);
    // let userData = myStore.userData;
    let appData = myStore.appData;
    let brandSetting = myStore.brandSetting;
    let [deviceName , setDeviceName ] = useState('');  

    // console.log(slugName, 'slugNameslugName');
    
    const navigate = useNavigate();

    // let tokenCookie = Cookies.get(`access_token_${slugName}`)
    // ? Cookies.get(`access_token_${slugName}`)
    // : false; 

    const location = useLocation();
    useEffect(()=>{
        let qsData = location.search.replace('?', '').split('&'),
        qsObj = {};
        for(let d of qsData){
            let t = d.split('=');
            qsObj[t[0]] = t[1];
        }
        let  pcName=qsObj['device-name'] || window.navigator.userAgentData?.platform || window.navigator.oscpu
        if (pcName !=null ) {
            pcName= pcName.split(" ")[0];
        }
        setDeviceName(pcName);
    },[location]);

    let urlLocationData = location.search.replace('?', '').split('&'),
    urlLocationObj = {};
    for(let d of urlLocationData){
        let t = d.split('=');
        urlLocationObj[t[0]] = t[1];
    }
    // console.log(urlLocationObj['devicekey'], 'qsObj');
    // let deviceKey = urlLocationObj['devicekey'];
    
    const queryParams = new URLSearchParams(location.search);
    const deviceKey = queryParams.get('devicekey');
   
    const getDeviceKeyData=()=>{
       
        let deviceData ={
            devicekey : deviceKey
        }
        if(deviceKey){
            common.getAPI({
                url: 'get-device-login-data',
                method: 'POST',
                data: deviceData
            }, (resp) => {
                
                if(resp?.device_data){
                    setdevicedata(resp?.device_data)
                    setMyState(setQuery,{
                        loginStatus : false, 
                        loginSession: true, 
                        loginBlocked: false
                    })
                }else{
                    toast.error(resp.message);
                }
                
                return;
            });
        }
    }

    useEffect(()=>{
        getDeviceKeyData()
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('code');
        let a1="relogin"
        if(myParam){
            authAction(a1, myParam, (authData) => {
                setIsProcess(false);
                if(authData?.device_exist){
                    setdevicedata(authData?.device_data)
                    setMyState(setQuery,{
                        loginStatus : false, 
                        loginSession: true, 
                    })
                    return;
                }else{
                    if(authData?.access_token){
                        myStore.updateStoreData('userData', authData);
                        navigate(`/dashboard`);
                    }
                }
            })
        }
    },[])
    let authType = 'login';
    
    let [isProcess , setIsProcess ] = useState(false);  
    let [devicedata,setdevicedata]=useState([])
    let [userDevice, setUserDevice]=useState();
    const [state, setQuery] = useState({
        name : '',
        email: '',
        password : '',
        resetPassword : false,
        forgotStatus : false,
        loginStatus : true,
        funnel_id : appData.funnel_id,
        confirmpassword: '',
        message : '',
        role : '',
        brnad_logo : '',
        themeColor : brandSetting.theme_color1,
        buttonColor : null,
        loginSession: false,
        isLogin: false,
        member_id : '',
        device_name : '',
        membership_device_id : '',
        base_url : baseUrl,
        loginBlocked: false,
        loginButtonBlocked:false,
    })
    let getButtonColor1 =(type)=>{
        setMyState(setQuery,{buttonColor : type})
    }
    let getButtonColor2 =(type)=>{
        setMyState(setQuery,{buttonColor : null})
    }
    let [error , setError ] = useState({
        email : '',
        password :'',
        funnel_id : '',
        device_name : '',
        base_url : '',
    }); 
    var myState = {};
    let manageState = () => {
        myState = {
            email : state.email,
            password : state.password,
            // confirmPassword : state.confirmPassword,
            funnel_id : appData.funnel_id,
            device_name : deviceName,
            base_url : baseUrl,
            // base_url : config.REACT_APP_BUCKET_URL,
        }
    }

    useEffect(() => {  
        manageState();    
    }, [state.email , state.password , myState, state.funnel_id]);

    let onFormSubmit = async (submitType , myState) => {      
        if(!myState.email.lenght == ''|| myState.email == ''){
            setError({
                ...error,
                email : 'Email field is required.',
            });
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(myState.email)) {
            setError({
                ...error,
                email : 'This email is not registered with us',
            });
        }else if(!myState.password.length){
            setError({
                ...error,
                password : 'Password field is required.'
            });
        }
        // else if(!validatePassword(myState.password)){
        //     setError({
        //         ...error,
        //         password : 'Invalid password.'
        //     });
        // }
        else {
            setIsProcess(true);
            authAction(submitType, myState, (authData) => {
                setIsProcess(false);
                // console.log(authData, 'authData?.device_exist');
                // return
                setUserDevice(authData);
                if(authData?.device_exist){
                    setdevicedata(authData?.device_data)
                    setMyState(setQuery,{
                        loginStatus : false, 
                        loginSession: true, 
                        loginBlocked: false,
                    })
                    // console.log(state.loginBlocked, 'loginBlocked1221');
                    // common.getAPI({
                    //     method: "Post",
                    //     url: "get-device-login-data",
                    //     data: {
                    //       devicekey : appData.funnel_id
                    //     },
                    //   },
                    //   (resp) => {
                    //     console.log(resp, 'Responce Devicekey');
              
                    // });
                    return;
                }else if(!authData?.device_exist && !authData?.access_token){
                    // console.log(authData, 'authData?authData?');
                    if(authData?.message != 'Unauthorized'){
                        setMyState(setQuery,{
                            loginStatus : false, 
                            loginBlocked : true, 
                            loginSession: false, 
                        })
                    }
                    console.log(state.loginBlocked, 'loginBlockedloginBlocked');
                    setUserDevice(authData);
                    console.log(authData, 'authData?.message');
                }else {
                    if(authData?.access_token){
                        myStore.updateStoreData('userData', authData);
                        // window.location.replace("/dashboard")
                        navigate(`/dashboard`);
                        
                    }
                }
            })
        }        
    }
    const forgetPassword=async(type, data)=>{
        setIsProcess(true);
        if(!data.email.length || myState.email == ''){
            setError({
                ...error,
                email : 'Email field is required.'
            });
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            setError({
                ...error,
                email : 'This email is not registered with us',
            });
            toast.error(error.email)
        } else{
            authAction(type, data , (authData) => {
                setMyState(setQuery, {
                    email : "", 
                    // resetPassword : true,
                    // forgotStatus : false,
                    funnel_id : appData.funnel_id
                })
                setIsProcess(false);
                
            })
        }
    }

    // Create an event handler so you can call the verification on button click event or form submit
    const handleSubmit = useCallback(async (e , type , stateDetails) => {
        e.stopPropagation();
        e.preventDefault();
        onFormSubmit(type , {
            ...stateDetails,
        });        
    }, []);
    
    const handleLogoutSession=(e, type,data,index)=>{
        let data1 ={
            membership_device_id : data?.membership_device_id,
            token: data.token,
          }
          common.getAPI({
            method: "Post",
            url: "logout",
            data: data1,
          },
          (resp) => {
            if(resp){
                let newdata = [...devicedata]
                newdata[index].status=0
                setdevicedata(newdata);
                if(resp?.success){
                    toast.success(resp.message);
                    window.location.replace('/login')
                }else {
                    toast.error(resp.message);
                }
            }
            Cookies.remove(`access_token_${slugName}`);
            myStore.updateStoreData('userData' , {});
            myStore.updateStoreData('script' , null);
        });
    }
    // useEffect(()=>{
    // },[state.devicedata]);

    // const [isloading, setIsloading] = useState(false)
    // useEffect(()=>{
    //     if(appData.funnel_id == null || appData.funnel_id == '') {
    //         setIsloading(true)
    //     }else{
    //         setIsloading(false)
    //     }
    // },[]);
    const[showPassword,setShowPassword] = useState({  
        password : false,
    })
 

  return (<>
    <Helmet>
        <title>{'Login'}</title>
        <link rel="shortcut icon" href={appData.favicon_url} type="image/png/ico" />
    </Helmet>
    <ToastContainer />
    
    <div className='mc_authWrapper relative' style={{backgroundImage: `url(${brandSetting.bgimg})`, backgroundColor : brandSetting.bgcolor, backgroundSize: brandSetting.bgtype, background : `${brandSetting.bgcolortype == 'color' ? brandSetting.bgcolor : brandSetting.bggradientColor} url(${brandSetting.bgimg}) ${brandSetting.bgcolor} 100% 100% / ${brandSetting.bgtype} `}}>
        <div id="mainPageLoader"  className={'mainPageLoader'}>
            <div className='mc_isLoaderDesign'></div>
        </div>
        <div className='mc_overlayBg' style={{backgroundColor : `${brandSetting.overlaycolor}`}}></div>
        <div className='mc_vertical_content relative'>
            <div className='mx-auto my-auto flex item-center justicy-center px-5'>
                {state.loginStatus == true ? 
                    <form className='mc_formDv' onSubmit={(e)=>handleSubmit(e, authType, myState)}>
                        <div className='mc_formLogo flex w-full flex-col gap-6'>
                            
                            {
                                brandSetting.logo ? 
                                    <img src={brandSetting.logo} alt='Logo' width={'250px'} height={'50px'}/>
                                :
                                    <div className='mc_logoText' style={{color: `${state.buttonColor == 'Logo' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}>Logo</div>
                            }
                            
                            <p className='mc_formText text-xl font-semibold text-black text-center w-56 mx-auto mb-3'>Login with <br/> your account now</p>
                            <div className='w-full mc_formGroup relative'>
                                <input type='email' className='mc_input ' variant="standard" label="Enter Your Email" onChange={(e)=> setMyState(setQuery, {email : e.target.value})} autoComplete="off" onFocus={()=> getButtonColor1('emailLabel')} onBlur={()=> getButtonColor2('emailLabel')}></input>
                                <label className='mc_authLabel' style={{color : `${state.buttonColor == 'emailLabel' ? brandSetting.theme_color2 : brandSetting.theme_color1 }`, transform: `${state.buttonColor == 'emailLabel' ?  'scale(0.8)' : 'scale(1)'}`, top : `${state.buttonColor == 'emailLabel' ? '5px' : '8px'}`}}  
                                >Enter Your Email</label>
                                <span style={{fill: brandSetting.theme_color2}}>{svg.app.emailIcon}</span>
                                {error.email && <p className='mc_errortext'>{error.email}</p>}
                            </div>
                            <div className='w-full mc_formGroup relative'>
                                <input type={showPassword.password ? 'text' : 'password'} className='mc_input' variant="standard" label="Enter Your Password"  onChange={(e)=> setMyState(setQuery, {password : e.target.value})}  autoComplete="off" onFocus={()=> getButtonColor1('PasswordLabel')} onBlur={()=> getButtonColor2('PasswordLabel')}></input>
                                <label className='mc_authLabel' style={{color : `${state.buttonColor == 'PasswordLabel' ? brandSetting.theme_color2 : brandSetting.theme_color1 }`, transform: `${state.buttonColor == 'PasswordLabel' ?  'scale(0.8)' : 'scale(1)'}`, top : `${state.buttonColor == 'PasswordLabel' ? '5px' : '8px'}`}}
                                    
                                >Enter Your Password</label>
                                {/* <span className='mc_fieldIcon' 
                                    onClick={()=>setShowPassword(prev => ({...prev,password: !prev.password,}))}
                                >
                                    {showPassword.password ? svg.app.eyeOpenIcon :svg.app.eyeCloseIcon}
                                </span> */}
                                <span style={{fill: brandSetting.theme_color2}}
                                    onClick={()=>setShowPassword(prev => ({...prev,password: !prev.password,}))}
                                >
                                    {/* {svg.app.passwordIcon} */}
                                    {showPassword.password ? svg.app.eyeOpenIcon :svg.app.eyeCloseIcon}
                                </span>
                                
                                {error.password && <p className='mc_errortext'>{error.password }</p>}
                            </div>
                            {slugName !='0xq22mj7' &&
                                <div className='w-full mx-3'>
                                    <a  className='mc_backToLoginBtn w-full text-center text-black text-lg font-semibold mx-auto inline-block' onClick={()=> setMyState(setQuery,{
                                        forgotStatus : true, loginStatus:false,
                                    })}  style={{color: `${state.buttonColor == 'Forgot Password' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} onMouseEnter={(e)=> getButtonColor1('Forgot Password')} onMouseLeave={(e)=> getButtonColor2('Forgot Password')}>Forgot Password?</a>
                                </div>
                            }
                            {state.loginButtonBlocked == false &&
                                <div className='w-full'>
                                    <button type="submit" className='mc_btn mc_formBtn text-center mc_themeColor1' style={{color:brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == `Login` ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} onMouseEnter={(e)=> getButtonColor1('Login')} onMouseLeave={(e)=> getButtonColor2('Login')}>{isProcess ? 'Processing...' : 'Login'}</button>
                                </div>
                            }
                        </div>
                    </form>
                : state.forgotStatus == true && 
                    <form className='mc_formDv'>
                        <div className='mc_formLogo flex w-full flex-col gap-6'>
                            
                                {
                                    brandSetting.logo ? 
                                        <img src={brandSetting.logo} alt='Logo' width={'250px'} height={'50px'}/>
                                    :
                                        <div className='mc_logoText' style={{color: `${state.buttonColor == 'Logo' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}>Logo</div>
                                }
                            <p className='mc_formText text-xl font-semibold text-black text-center w-56 mx-auto mb-3'>Forgot Password</p>
                            <div className='w-full mc_formGroup relative'>
                                <input type='email' className='mc_input ' variant="standard" label="Enter Your Email" onChange={(e)=> setMyState(setQuery, {email : e.target.value})} autoComplete="off" onFocus={()=> getButtonColor1('emailLabel')} onBlur={()=> getButtonColor2('emailLabel')}></input>
                                <label className='mc_authLabel' style={{color : `${state.buttonColor == 'emailLabel' ? brandSetting.theme_color2 : brandSetting.theme_color1 }`, transform: `${state.buttonColor == 'emailLabel' ?  'scale(0.8)' : 'scale(1)'}`, top : `${state.buttonColor == 'emailLabel' ? '5px' : '8px'}`}}  
                                >Enter Your Email</label>
                                <span style={{fill: brandSetting.theme_color2}}>{svg.app.emailIcon}</span>
                                {error.email && <p className='mc_errortext'>{error.email}</p>}
                            </div>
                            <div className='w-full'>
                                <button type="button" className='mc_btn mc_formBtn text-center' onClick={()=> forgetPassword( "forgot_password",myState )} style={{color:brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == `Send Mail` ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} onMouseEnter={(e)=> getButtonColor1('Send Mail')} onMouseLeave={(e)=> getButtonColor2('Send Mail')}>{isProcess ? 'Processing...' : 'Send Mail'}</button>

                                <a href={() => false} className='mc_backToLoginBtn w-full text-center text-black text-lg font-semibold mx-auto inline-block mt-3' onClick={()=> setMyState(setQuery,{
                                    forgotStatus : false, loginStatus : true, 
                                })}  style={{color: `${state.buttonColor == 'Back to Login' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} onMouseEnter={(e)=> getButtonColor1('Back to Login')} onMouseLeave={(e)=> getButtonColor2('Back to Login')}>Back to Login</a>
                            </div>
                        </div>
                    </form>
                }
                {state.loginSession == true &&
                    <div className='mc_formDv'>
                        <div className='mc_formLogo flex w-full flex-col gap-6'>
                                {
                                    brandSetting.logo ? 
                                        <img src={brandSetting.logo} alt='Logo' width={'250px'} height={'50px'}/>
                                    :
                                    <div className='mc_logoText' style={{color: `${state.buttonColor == 'Logo' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}>Logo</div>
                                }
                        { userDevice && <p className='mc_formText text-xl font-semibold text-black text-center mx-auto'>{userDevice.message_headline}</p>}
                        { userDevice &&  <p className='text-base font-medium text-gray-600 text-center mx-auto'>{userDevice.message}</p>}
                            <div className='mc_memberSession_device flex flex-col gap-4'>
                                {devicedata?.length ? devicedata.map((data, i )=>{
                                    // console.log(data.device_name, 'data.device_name');
                                    return(
                                        <div key={i} className='mc_boxSession flex items-center'>
                                            <div className='flex items-center w-full'>
                                                <span className='mc_desktopIcon'>{svg.app.desktopIcon}</span>
                                                <div className='mc_sessionContent'>
                                                    <p className='text-gray-500 text-xs'>Session {i+1}</p>
                                                    <p className='text-gray-500 text-xs'>Device : <span className='text-black text-sm'>{data.device_name}</span></p>
                                                </div>
                                                {data.status == 1  ? 
                                                    <a href={() => false} className='mc_btn' style={{color:brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == `Session${i+1}` ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                        onClick={(e)=> handleLogoutSession(e, 'logout',data,i)}
                                                        onMouseEnter={(e)=> getButtonColor1(`Session${i+1}`)} onMouseLeave={(e)=> getButtonColor2(`Session${i+1}`)}
                                                    >{'Logout'}</a>
                                                :
                                                    <a href={() => false} className='mc_btnText font-semibold' style={{color: `${state.buttonColor == `Session${i+1}` ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                        // onClick={(e)=> handleLogoutSession(e, 'logout',data,i)}
                                                        onMouseEnter={(e)=> getButtonColor1(`Session${i+1}`)} onMouseLeave={(e)=> getButtonColor2(`Session${i+1}`)}
                                                    >{'Logged Out'}</a>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                : ''}
                            </div>
                          
                        
                            
                            <div className='w-full'>
                                <a href={() => false} className='mc_backToLoginBtn mc_sessionBtnLink w-full text-center text-black text-lg font-semibold mx-auto inline-block mt-3' 
                                    onClick={()=> setMyState(setQuery,{
                                        forgotStatus : false, loginStatus : true, loginSession: false, loginBlocked: false,
                                    })}  
                                    style={{
                                        color: `${state.buttonColor == 'Back to Login' ? brandSetting.theme_color2 : brandSetting.theme_color1}`
                                    }}
                                    onMouseEnter={(e)=> getButtonColor1('Back to Login')} 
                                    onMouseLeave={(e)=> getButtonColor2('Back to Login')}
                                >
                                    <span className='mc_arrowREvers' 
                                        style={{
                                            fill: `${state.buttonColor == 'Back to Login' ? brandSetting.theme_color2 : brandSetting.theme_color1}`
                                        }} 
                                        onMouseEnter={(e)=> getButtonColor1('Back to Login')} 
                                        onMouseLeave={(e)=> getButtonColor2('Back to Login')}
                                    >
                                        {svg.app.arrowLeftIcon}</span> Back to Login
                                    </a>
                            </div>
                        </div>
                    </div>
                }
                {state.loginBlocked == true && 
                <>
                    <div className='mc_formDv'>
                        <div className='mc_formLogo flex w-full flex-col gap-6'>
                                {
                                    brandSetting.logo ? 
                                        <img src={brandSetting.logo} alt='Logo' width={'250px'} height={'50px'}/>
                                    :
                                    <div className='mc_logoText' style={{color: `${state.buttonColor == 'Logo' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}>Logo</div>
                                }
                            <p className='mc_formText text-xl font-semibold text-black text-center mx-auto'>{userDevice.message_headline}</p>
                            <p className='text-base font-medium text-gray-600 text-center mx-auto'>{userDevice.message}</p>
                            <div className='w-full'>
                                <a href={() => false} className='mc_backToLoginBtn mc_sessionBtnLink w-full text-center text-black text-lg font-semibold mx-auto inline-block mt-3' 
                                    onClick={()=> setMyState(setQuery,{
                                        forgotStatus : false, loginStatus : true, loginSession: false, loginBlocked: false, loginButtonBlocked: true,
                                    })}  
                                    style={{
                                        color: `${state.buttonColor == 'Back to Login' ? brandSetting.theme_color2 : brandSetting.theme_color1}`
                                    }}
                                    onMouseEnter={(e)=> getButtonColor1('Back to Login')} 
                                    onMouseLeave={(e)=> getButtonColor2('Back to Login')}
                                >
                                    <span className='mc_arrowREvers' 
                                        style={{
                                            fill: `${state.buttonColor == 'Back to Login' ? brandSetting.theme_color2 : brandSetting.theme_color1}`
                                        }} 
                                        onMouseEnter={(e)=> getButtonColor1('Back to Login')} 
                                        onMouseLeave={(e)=> getButtonColor2('Back to Login')}
                                    >
                                        {svg.app.arrowLeftIcon}</span> Back to Login
                                    </a>
                            </div>
                        </div>
                    </div>

                </>
                }
            </div>
        </div>
    </div>
    </>
  )
}
