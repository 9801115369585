import React, { useEffect } from 'react';
import './globals.css';
import Nav from './components/Nav';
import Layout from './components/Layout';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { appStore } from './zustand/store';
import { common } from './components/Comman';
import InviteCode from './components/inviteCode';
import Help from './pages/help';
import EventRoutes from './pages/events/eventRoutes';

function App(props) {
  let slugName = window.location.href.split('/')[4];

  let myStore = appStore(state => state);


  const addChild = (s1, body) => {

    if (body == "head") {
      document.head.append(s1)
    } else if (body == "bodyend") {
      document.body.append(s1)
    } else {
      document.body.insertBefore(s1, document.body.firstChild);

    }
  }

  useEffect(() => {

    let funnel_id = document.querySelector('meta[name="funnel_id"]')?.content || null;
    myStore.updateStoreData('slug_name', slugName);
    let data1 = document.getElementById("mainPageLoader")
    if (data1) {
      data1.classList.add("isopen")
    }

    if (funnel_id) {
      common.getAPI({
        method: "Post",
        url: "getfunnel",
        data: {
          funnel_id
        },
      },
        (resp) => {
          // console.log(resp, 'Funnercdfsdfdsf');
          // return;
          let funnelDetails = resp.funnel_data[0];

          if (funnelDetails) {
            let data3 = document.getElementById("mainPageLoader")


            setTimeout(() => {
              if (data3?.classList?.contains("isopen")) {
                data3.classList.remove("isopen")
              }
            }, 2000);

            // getCustomeScriptData(funnelDetails)
            let brandSetting = JSON.parse(funnelDetails.brand_settings);
            myStore.updateStoreData("appData", funnelDetails);
            myStore.updateStoreData("brandSetting", brandSetting);
            // document.documentElement.style.setProperty('--themeColor2', brandSetting?.theme_color1) ;
            // document.documentElement.style.setProperty('--theme_textColor_btn', brandSetting?.data.theme_button_txt_color) ;

            if (funnelDetails?.funnel_id) {
              getCustomeScriptData(funnelDetails.funnel_id)
            }
        
          } else {
            // data3.classList.add("isopen")
          }

        });
    }
  }, [])

  useEffect(() => {
    if (window.location.href.split('/')[3] == 'events') {
      let event = document.getElementById("eventPageLoader")
      if (event) {
        document.addEventListener('DOMContentLoaded', event.classList.add("isopen"))
      }
      setTimeout(() => {
        if (event?.classList?.contains("isopen")) {
          event.classList.remove("isopen")
        }
      }, 2000);
    }
  }, [])

  useEffect(() => {
    let url = window.location.pathname
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let devicename= urlParams.get("device-name")
    let firebase_token= urlParams.get("firebase_token")
    if(firebase_token )
    {
        localStorage.setItem("userDevice", JSON.stringify({
          firebase_token,
          devicename
        }))
        window.location.href="/events"
    }
    if (!url.includes("events")) {
      // let funnel_id = myStore.userData?.MembershipUser?.funnel_id
      if (myStore?.script) {
        loadSC(myStore?.script)
        return
      }
    }
  }, [])
  const loadSC = (data) => {
    // console.log({data})
    let json = JSON.parse(data)
    Object.keys(json).forEach((d5) => {
      let htmlString = json[d5];
      if (htmlString != "") {
        let tempContainer = document.createElement('div');
        tempContainer.innerHTML = htmlString;
        let scriptTags = tempContainer.getElementsByTagName('script');
        let scriptSrcs = [];
        let len = scriptTags.length
        for (let i = 0; i < len; i++) {
          // console.log(i,scriptTags[0].getAttribute('src'))
          if (scriptTags[0].getAttribute('src')) {
            let d1 = {}
            d1.src = scriptTags[0].getAttribute('src')
            scriptSrcs.push(d1)
          } else {
            let d1 = {}
            d1.code = scriptTags[0].innerHTML
            scriptSrcs.push(d1)
          }

          scriptTags[0].remove();
        }

        // console.log({scriptSrcs})
        for (let i = 0; i < scriptSrcs.length; i++) {
          if (scriptSrcs[i]?.src) {
            let s1 = document.createElement("script")
            s1.src = scriptSrcs[i].src
            addChild(s1, d5)
            //  document.body.append(s1)
          } else {
            let s1 = document.createElement("script")
            s1.type = "text/javascript"
            // console.log(scriptSrcs[i].code,"ccccooooodddderrr")
            s1.innerHTML = scriptSrcs[i].code
            addChild(s1, d5)
          }
        }
      }

    })
  }

  const getCustomeScriptData = async (funnel_id) => {
    let footerData = {
      funnel_id: funnel_id,
    }
    common.getAPI({
      method: "Post",
      url: "customscript",
      data: footerData,
    }, (resp) => {
      if (resp.success == true) {
        if (resp?.custom_script != "No custom script found") {
          // console.log(resp.custom_script==myStore?.script,"resp.custom_script==myStore?.script",resp.custom_script,"jhhhhh",myStore?.script)
          if (resp.custom_script == myStore?.script) {
            return
          }
          // let json = JSON.parse(resp.custom_script)
          myStore.updateStoreData('script', resp.custom_script);
          // Object.keys(json).forEach((d5)=>{
          //   let htmlString = json[d5];
          //   if(htmlString!="")
          //   {
          //     let tempContainer = document.createElement('div');
          //     tempContainer.innerHTML = htmlString;
          //     let scriptTags = tempContainer.getElementsByTagName('script');
          //     let scriptSrcs = [];
          //     let len=scriptTags.length
          //     for (let i = 0; i < len; i++) {
          //       console.log(i,scriptTags[0].getAttribute('src'))
          //         if(scriptTags[0].getAttribute('src'))
          //         {
          //           let d1={}       
          //           d1.src=scriptTags[0].getAttribute('src')
          //          scriptSrcs.push(d1)
          //         }else{
          //          let  d1={}
          //          d1.code=scriptTags[0].innerHTML
          //          scriptSrcs.push(d1)
          //         }

          //         scriptTags[0].remove();
          //     }

          //     console.log({scriptSrcs})
          //     for(let i=0;i<scriptSrcs.length;i++)
          //     {
          //       if(scriptSrcs[i]?.src)
          //       {
          //         let s1= document.createElement("script")
          //         s1.src=scriptSrcs[i].src
          //         addChild(s1,d5)
          //       //  document.body.append(s1)
          //       }else{
          //       let s1= document.createElement("script")
          //         s1.type="text/javascript"
          //         s1.innerHTML=scriptSrcs[i].code  
          //         addChild(s1,d5)
          //       }
          //     }
          //   }

          // })
          window.location.reload()
        }

      }
    });
  }

  // get-device-login-data
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />  */}
          {(window.location.hostname == 'course-membership.pixelnx.com' || window.location.hostname == "localhost" || window.location.hostname == "desktop-tht36b3") && <>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta name="description" content="" />
            <link rel="canonical" href="mdkemz.flexifunnels.com" />
            <meta property="og:url" content="mdkemz.flexifunnels.com" />
            <meta itemprop="url" content="mdkemz.flexifunnels.com" />
            <meta name="twitter:url" content="mdkemz.flexifunnels.com" />
            <meta property="og:site_name" content="My Learning" />
            <meta property="og:title" content="My Learning" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="" />
            <meta property="og:image" content="" />
            <meta itemprop="name" content="My Learning" />
            <meta itemprop="description" content="" />
            <meta name="twitter:title" content="My Learning" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content="" />
            <meta name="category_id" content="17" />
            <meta name="keywords" content="" />
            <meta name="author" content="" />
            
            {/* <meta name="funnel_id" content="LqReJxwnqQrZP7Nm"/>
            <meta name="funnel_page_id" content="ZBEJavZL7OLxg3eq"/> */}

            {/* DRM videos */}
            {/* <meta name="funnel_id" content="LqReJxwnqQrZP7Nm"/>
            <meta name="funnel_page_id" content="6N47ZxVEBK2rqDdY"/> */}
            {/* <meta name="funnel_id" content="dGLEBrY6opxemWMX"/>
            <meta name="funnel_page_id" content="d5ByJxPp26Px6WKZ"/> */}
            {/* <meta name="funnel_id" content="MyYRpx0MVLj8Vq4J"/>
            <meta name="funnel_page_id" content="M69YAxNwX8yjglPo"/> */}
            
            {/* <meta name="funnel_id" content="dGLEBrY6opxemWMX"/>
            <meta name="funnel_page_id" content="d5ByJxPp26Px6WKZ"/> */}
            {/* <meta name="funnel_id" content="kBDp9xX88mRxXoyK"/>
            <meta name="funnel_page_id" content="pB6QGrWARYqjl7z8"/> */}
{/* LevelUp  */}
            {/* <meta name="funnel_id" content="yDZW3rgaoRjRQdqz"/>
            <meta name="funnel_page_id" content="ZRbqlrLRAqDjBk76"/> */}

            {/* For Over Testing Slug 9xxwx1rx */}
            <meta name="funnel_id" content={"kBDp9xXWX1rXoyK5"} />
            <meta name="funnel_page_id" content="MyYRpx09299j8Vq4" />


{/* Full screen issue youtube */}
            {/* <meta name="funnel_id" content="LqReJxw77GyxZP7N"/>
            <meta name="funnel_page_id" content="yDZW3rg0YDbxRQdq"/> */}
          {/* <meta name="funnel_id" content="kBDp9xXWX1rXoyK5"/>
          <meta name="funnel_page_id" content="5LqAOxKVqNBrn7zM"/> */}

        {/* <meta name="funnel_id" content="NdOeBj2od3qvbJ2P"/>
        <meta name="funnel_page_id" content="DJOmnry37D4jKEV4"/> */}

        {/* Drm videos Babjai  */}
        {/* <meta name="funnel_id" content="NdOeBj2od3qvbJ2P"/>
        <meta name="funnel_page_id" content="lVdK0xqw4LZv7g9R"/> */}

            {/* Floating video test */}
            {/* <meta name="funnel_id" content="D7bGQjkm2JErK2mJ"/>
            <meta name="funnel_page_id" content="QzWPLr6qdggv9Mda"/> */}
          </>
          }
          {/* <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" /> */}
        </Helmet>
        {window.location.href.split('/')[3].split('?')[0] == 'events' ?
          <BrowserRouter basename={`/`}>
            <EventRoutes />
          </BrowserRouter>
          :
          slugName ?
            <BrowserRouter basename={`/membership/${slugName}`}>
              <Layout>
                <div className="App" style={{ position: 'relative' }}>

                  <main className="relative bg-gray-100">
                    <Nav />
                  </main>
                  <ToastContainer />
                </div>
              </Layout>
            </BrowserRouter>
            :
            <BrowserRouter>
              <Routes>
                <Route exact path={'/'} element={<InviteCode />}></Route>
                <Route exact path={'/help'} element={<Help />}></Route>
              </Routes>
            </BrowserRouter>
        }
      </HelmetProvider>
    </>
  );
}

export default App;
